<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card :loading="loading" v-if="!membership">
        <v-card-text class="text-center">
           <img
            v-if="$vuetify.theme.dark"
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
          <img
            v-else
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />
          <template v-if="loading && !error">
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-information-outline"
              type="info"
            >
              Verificando filiação, por favor aguarde
            </v-alert>
          </template>
          <template v-else>
            <v-alert
              border="left"
              class="text-left"
              text
              icon="mdi-alert-circle-outline"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-btn
              color="secondary"
              class="mt-4"
              block
              :to="{
                name: 'sign-in',
              }"
            >
              Voltar
            </v-btn>
          </template>
        </v-card-text>
      </base-card>
      <div v-if="membership">
        <membership-card :value="membership" />
        <v-btn block href="/" text class="mt-2" color="white">
          ir para o app
        </v-btn>
      </div>
    </div>
    <re-captcha
      ref="recaptcha"
      auto-run
      @error="recaptchaError"
      @success="readToken"
      @loaded="processRecaptcha"
      @close="recaptchaError"
    ></re-captcha>
  </div>
</template>
<script>
import ReCaptcha from "@/components/global/ReCaptcha";
import VERIFY from "@/services/verify";
import { mapGetters } from "vuex";
import MembershipCard from "../../components/global/MembershipCard.vue";

export default {
  name: "SignUp",
  metaInfo: {
    title: "Verificar Filiação",
  },
  data() {
    return {
      show: false,
      loading: true,
      error: false,
      innerLoading: false,
      innerError: false,
    };
  },
  methods: {
    recaptchaError(e) {
      this.loading = false;
      this.innerLoading = false;
      this.error = "Erro ao validar recaptcha, atualize a página e tente novamente.";
    },
    async readToken(recaptcha) {
      try {
        let id = this.$route.params.id;
        let { membership } = await VERIFY.membership(id, recaptcha);
        this.loading = false;
        this.innerLoading = false;
        this.membership = membership;
        
        if (membership.User.id == this.user.id)
          this.$router.replace({
            name: "app.membership.details",
            params: { id },
          });
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
  },
  computed: {
    ...mapGetters("app", ["filiations"]),
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    let id = this.$route.params.id;
    if (!id) {
      this.loading = false;
      this.error =
        "Você precisa acessar essa página a partir do link enviado para seu WhatsApp. Não recebeu o link? Clique em voltar e tente novamente.";
    }
    if (this.filiations && this.filiations.length) {
      let membership = this.filiations.find((f) => f.id == id);
      if (membership)
        return this.$router.replace({
          name: "app.membership.details",
          params: { id },
        });
    }
  },
  components: {
    ReCaptcha,
    MembershipCard,
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(circle at 50% 50%, rgba(0,0,0,50%) 0%, rgba(0,0,0,90%) 90vw),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
